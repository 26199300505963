import React, {createContext, useState} from "react";
import ApiService from "../_services/ApiService";
import UserService from "../_services/UserService";
import Config from "../_config/config";

const DEFAULT_TEST_RESULT_OBJ = {
    testId: -1,
    testResult: {}
}

export const TestResultContext = createContext({
    getTestResult: async()=>{},
    testResultObj: DEFAULT_TEST_RESULT_OBJ
});

export const TestResultProvider = ({children})=>{
    const [testResultObj, setTestResultObj] = useState(DEFAULT_TEST_RESULT_OBJ);
    const getTestResult = async(testId)=>{
        if(Number(testId) === Number(testResultObj.testId)){
            return testResultObj.testResult;
        }

        const body = {
            testId,
            userId: UserService.getUserId(),
            tenant: Config.siteTitle,
            enquiryId: UserService.getEnquiryId()
          }
        try{
            const testResult = await ApiService.questionWiseAnalysis(body);
            console.log(testResult);
            setTestResultObj({
                testId,
                testResult
            });
            return testResult;
        }catch(error){
            Promise.reject(error);
        }

    };
    const value = {
        getTestResult,
        testResultObj,
    }
    return <TestResultContext.Provider value={value}>{children}</TestResultContext.Provider>
}

